<template>
  <div @click="addToCart" :class="heart"></div>
</template>

<script>
export default {
  data() {
    return {
      heartClicked: false,
    }
  },
  props: ['id'],
  mounted() {
    let cart = localStorage.cart;
    cart = JSON.parse(cart);
    cart.forEach(element => {
      if (element['id'] == this.id) {
        this.heartClicked = !this.heartClicked;
        this.qty = element['qty'];
      }
    });
  },
  computed: {
    heart() {
      return {
        'heart': true,
        'heart-not-clicked': !this.heartClicked,
        'heart-clicked': this.heartClicked
        }
    },
  },
  methods: {
    addToCart(event) {
      event.preventDefault();
      this.$emit('del', this.id);
      let cart = localStorage.cart;
      if (cart == '') {
        this.heartClicked = !this.heartClicked;
        cart = JSON.stringify([{id: this.id, qty: 1}]);
      }
      else {
        cart = JSON.parse(cart);
        console.log(cart);
        let includes = false;
        cart.forEach(element => {
          if (element['id'] == this.id) {
            includes = true;
          }
        });
        if (!includes) {
          this.heartClicked = !this.heartClicked;
          cart.push({id: this.id, qty: 1});
        }
        else {
          this.heartClicked = !this.heartClicked;
          for(var i = 0; i < cart.length; i++) {
            console.log(cart[i])
            if (cart[i]['id'] == this.id) {
              cart.splice(i,1);
            }
          }
        }
        cart = JSON.stringify(cart);
      }
      localStorage.cart = cart;
    }
  },
}
</script>

<style scoped>
.heart {
  position: relative;
  left: 20px;
  top: 20px;
  width: 29px;
  height: 40px;
  z-index: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.heart:hover {
  animation: shake 0.5s;
  -webkit-animation: shake 0.5s;
  -moz-animation: shake 0.5s;
}

.heart-clicked {
  background: url('../../assets/wishlist_blue_2.png') center center no-repeat;
  background-size:27px 40px;
}

.heart-not-clicked {
  background:url('../../assets/images/decors/whishlist.png') center center no-repeat;
  background-size:27px 40px;
}
</style>