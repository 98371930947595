<template>
<button>
  <slot>
  </slot>
</button>
</template>

<script>
export default {
  
}
</script>

<style scoped>
button {
  display: inline-block;
  font-weight: 400;
  border: 1px solid #6A7078;
  color: #6A7078;
  font-size:13px;
  text-decoration: none;
  background-color: white;
  margin: 5px;
  padding:9px 10px 9px 10px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-align: center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
}
button:hover {
  background-color: #071A8D;
  border: 1px solid #071A8D;
  color: white;
}
</style>