<template>
<div class="home-slider">
    <div class="slide slide-img-txt slide8">
        <div class="content-1500">
            <div class="welcome-title">
                <p class="title" :data-text="$t('SliderWhoWeAre')">BSG <span class="pro">pro</span></p>
                <hr class="spacer">
                <p class="text">{{$t('SliderTxt')}}</p>
            </div>
        </div>
    </div>
    <div class="slide slide-img slide9">
    </div>
    <div class="slide slide-img slide10">
    </div>
    <div class="slide slide-text">
        <figure class="image-50 image1"></figure>
        <div class="slide-right">
            <div class="content center-vertically">
                <p class="title" :data-text="$t('SliderOurVision')">{{$t('AboutSliderP1')}}</p>
                <hr class="spacer">
                <p class="text">{{$t('AboutSliderP2')}}</p>
                <p class="text" :data-text="$t('SliderOurMission')">{{$t('AboutSliderP3')}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import AOS from "aos";
import 'slick-carousel';
export default {
  mounted() {
    $('.home-slider').not('.slick-initialized').slick({
        infinite: true,
        dots: true,
        arrows: false,
        speed: 1200,
        autoplay: true,
        pauseOnHover: false,
    });
    AOS.init({
        disable: 'mobile',
        once: true
    });
  }
}
</script>

<style scoped>
  .slide8 {
      background:url('../../assets/images/slider/slide1.jpg?v=21042022') center center no-repeat;
      background-size:cover;
    }

  .slide9 {
    background:url('../../assets/images/slider/slide2.jpg?v=21042022') center center no-repeat;
    background-size:cover;
  }

  .slide10 {
    background:url('../../assets/images/slider/slide3.jpg?v=21042022') center center no-repeat;
    background-size:cover;
  }

  .image1 {
    background:url('../../assets/images/slider/slide4.png?v=21042022') center center no-repeat;
    background-size:cover;
  }
</style>