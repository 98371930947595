<template>
    <div class="cookies" v-show="showCookies">
        <p class="cookies-text">
            {{$t('CookiesBanner')}}
            <router-link to="/gdpr" class="cookies-link">{{$t('CookiesPolicy')}}</router-link>
            <br><br>
        </p>
        <main-button class="cookies-button" @click="setCookies">{{$t('CookiesButton')}}</main-button>
        <router-link to="/" class="cookies-link" v-on:click="showModal = true">{{$t('CookiesParameter')}}</router-link>
        <Modal v-show="showModal" @close-modal="showModal = false" />
    </div>
</template>

<script>
  import Modal from '../UI/Modal.vue';
  export default {
    components: { Modal },
    methods:{
      setCookies() {
        this.$cookies.set('cookies', 'cookies', '1d');
        this.showCookies = false
      }
    },
    data() {
      return {
        showCookies: !this.$cookies.isKey('cookies'),
        showModal: false
      };
    }
  };
</script>

<style scoped>
  .cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 180;
    height: 150px;
    background-color: black;
    text-align: center;
  }
  .cookies-text {
    color: white;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
    white-space: pre-line;
    line-height: 1.5;
  }
  .cookies-link {
    font-family: HelveticaBold;
    color:white
  }
  .cookies-button {
    width: 150px;
    height: 35px;
    margin-right: 50px;
    font-family: HelveticaBold;
    background-color: white;
    color: black;
  }
  @media only screen and (max-width:700px) {
    .cookies-text {
      overflow-y: scroll;
      height: 125px;
      -webkit-mask-image: linear-gradient(to top, rgba(255,255,255,0) 0, #000 2.4rem);
      mask-image: linear-gradient(to top, rgba(255,255,255,0) 0, #000 2.4rem);
    }
  }
  @media only screen and (max-width:1200px) {
    .cookies {
        height: 200px;
      }
      .cookies-text {
        margin-left: 30px;
        margin-right: 30px;
      }
  }
</style>