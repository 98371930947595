<template>
<div class="subpage subpage-contact">
  <div class="content-1500">
      <div class="contact">
          <section class="text">
              <h2 class="title">{{$t('ContactHeaderH2')}}</h2>
              <hr class="spacer">
              <p class="para">{{$t('ContactHeaderp1l1')}}<br>
                  {{$t('ContactHeaderp1l2')}}<br>
                  {{$t('ContactHeaderp1l3')}}</p>
          </section>
      </div>
      <div class="contact-bottom">
          <div class="representatives">  

              <div class="rowx">
                  <figure class="img img2"></figure>
                  <div class="text">
                      <h3 class="title">Franck Yaya-Chérif</h3>
                      <p class="para">{{$t('ContactPerson2p1')}}</p>
                      <p class="para">{{$t('ContactPerson2p2')}}</p>
                      <p class="para"><a class="animate" href="tel:+33756107083">+33 756 107 083</a></p>
                      <p class="para"><a class="animate" href="mailto:franck.yc@besolux.com">franck.yc@besolux.com</a></p>
                  </div>
              </div>
              
  
              <div class="address">
                  <h4 class="title">{{$t('ContactAddress')}}</h4>
                  <p class="para">Luxobesolux Sarl</p>
                  <p class="para">8 rue de Beggen</p>
                  <p class="para">L-1220 Luxembourg</p>
                  <p class="para">Luxembourg</p>
                  <p class="para">TVA: LU28279037</p>
              </div>
          </div>
          <div class="form">
              <h4 class="title">{{$t('ContactFormTxt')}}</h4>
              <form method="post" @submit.prevent = "sendEmail">
                  <div class="rowx">
                      <input class="text-input animate" v-model="first_name" type="text" name="" :placeholder="$t('ContactFormName')" required />
                      <input class="text-input animate" v-model="last_name" type="text" name="" :placeholder="$t('ContactFormSurname')" required />
                  </div>
                  <div class="rowx">
                      <input class="text-input animate" v-model="company" type="text" name="" :placeholder="$t('ContactFormCompany')" required />
                  </div>
                  <div class="rowx">
                      <input class="text-input animate" v-model="activity_area" type="text" name="" :placeholder="$t('ContactFormActivityArea')" required />
                  </div>
                  <div class="rowx">
                      <input class="text-input animate" v-model="country" type="text" name="" placeholder="Country" required />
                  </div>
                  <div class="rowx">
                      <input class="text-input animate" v-model="address" type="text" name="" :placeholder="$t('ContactFormAddress')" required />
                  </div>
                  <div class="rowx">
                      <input class="text-input animate" v-model="email" type="email" name="" :placeholder="$t('ContactFormEmail')" required />
                      <input class="text-input animate" v-model="phone" type="text" name="" :placeholder="$t('ContactFormPhone')" required />
                  </div>
                  <div class="rowx">
                      <textarea class="textarea animate" v-model="message" name="" :placeholder="$t('ContactFormMessage')" required></textarea>
                  </div>
                  <div class="submit">
                      <input class="submit-btn animate hover" type="submit" :value="$t('ContactFormSend')" />
                      <vue-recaptcha @verify="verifyCaptcha" sitekey="6Lc4H1kgAAAAANHMbG2hSIKKOpftbK_bNcfVuO92"></vue-recaptcha>
                  </div>
              </form>
          </div>
      </div>
      <div class="map-bg"></div>
      <div class="bottom-images">
          <img src="../../assets/images/contact-bottom-1.png" alt="" />
          <img src="../../assets/images/contact-bottom-2.png" alt="" />
          <img src="../../assets/images/contact-bottom-3.png" alt="" />
          <img src="../../assets/images/contact-bottom-4_2.png" alt="" />
          <img src="../../assets/images/contact-bottom-5.png" alt="" />
      </div>
  </div>
</div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
 components: { VueRecaptcha },
 data() {
   return {
     first_name : '',
     last_name : '',
     company : '',
     activity_area : '',
     country : '',
     email : '',
     phone : '',
     message : '',
     address : '',
     captcha: false
   };
  },
  methods: {
    verifyCaptcha() {
      this.captcha = true;
    },
    sendEmail() {
      if (this.captcha) {
        const api = this.apiLink + "email/";
        console.log(api);
        this.axios.post(api, {
          first_name : this.first_name,
          last_name : this.last_name,
          company: this.company,
          activity_area: this.activity_area,
          country: this.country,
          address: this.address,
          email: this.email,
          phone: this.phone,
          message: this.message,
        }).then(function () {
        })
        this.captcha = false;
        window.location.reload();
      }
    }
  }

}
</script>

<style scoped>
  .img1 {
    background:url('../../assets/images/contact/carola-bedos.png?v=13052022') center center no-repeat;
    background-size:cover;
  }

  .img2 {
    background:url('../../assets/images/contact/franck-yaya-cherif.png?v=13052022') center center no-repeat;
    background-size:cover;
  }
    .img3 {
    background:url('../../assets/images/contact/kamil_adamus.png') center center no-repeat;
    background-size:cover;
  }
</style>
