<template>
  <router-link :to="{path: '/furnitures/', query: {furnitureCategory: lookfor}}">
    <div class="tile">
      <div>
        <img :src="img">
        <!-- <div class="furniture-image"></div> -->
        <span>{{ name }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>

export default ({
  props: ['name', 'img', 'lookfor']
});
</script>

<style scoped>
a {
  color:black;
}

.tile {
  color: black;
  text-decoration: none;
}

.tile:hover {
    color: #0056b3;
    cursor: pointer;
}

.tile:hover div span {
  color: black;
  text-decoration: underline;
}

.tile:hover div{
  background-color: #f8e572;
  filter: invert(100%);
}

div {
  text-align: center;
  align-items: center;
  padding: 0px;
  margin: 10px;
}

img {
  width: 201px;
  height: 183px;
  display: flex;
  vertical-align: middle;
  border-style: none;
}

span {
  position: relative;
  bottom: 20px;
}
</style>