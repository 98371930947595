<template>
  <input v-model="searchValue" type="text" :placeholder="$t('search')" @keyup.enter="acceptInput" />
</template>

<script>
export default {
  data() {
    return {
      searchValue: null,
    }
  },
  methods: {
    acceptInput() {
      this.$router.push({path: 'furnitures', query: {search: this.searchValue}});
    }
  }
}
</script>

<style>
input {
  display: inline-block;
  font-weight: 400;
  border-radius: 25px;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: black;
}
input:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #071a8d;
}
</style>