<template>
  <div>
  <the-slider @scroll="scrollTo"></the-slider>
  <div class="cooperation-icons-wrapper">
      <div class="content-1500">
          <div class="cooperation-icons">
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-3d.png" alt="BSG concept - 3D models"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon1txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-customization.png" alt="BSG concept - Customization"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon2txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-single-point.png" alt="BSG concept - Single point of contact"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon3txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-no-minimum-order.png" alt="BSG concept - No minimum order required"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon4txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-consultancy.png" alt="BSG concept - Interior design consultancy"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon5txt')}}</p>
              </div>
          </div>
      </div>
  </div>
  <div class="cooperation-row target-1">
      <div class="cooperation-slider">
          <div class="slide slide1"></div>
      </div>
      <div class="text" data-aos="fade-left">
          <h3 class="title">{{$t('ArchitectsH3Title')}}</h3>
          <ul class="list">
              <li>{{$t('ArchitectListEl1')}}</li>
              <li>{{$t('ArchitectListEl2')}}</li>
              <li>{{$t('ArchitectListEl3')}}</li>
              <!-- <li>{{$t('ArchitectListEl4')}}</li> -->
          </ul>
          <p class="para">{{$t('ArchitectPar1')}}</p>
          <div class="links">
              <router-link to="/contact" class="link link-pink animate">{{$t('ContactUs')}}</router-link>
              <router-link to="/welcome" class="link animate">{{$t('CheckOurProducts')}}</router-link>
          </div>
      </div>
  </div>
  <div class="cooperation-row cooperation-row-reversed target-2">
      <div class="cooperation-slider">
          <div class="slide slide2"></div>
      </div>
      <div class="text" data-aos="fade-right">
          <h3 class="title">{{$t('HospitalityH3Title')}}</h3>
          <ul class="list">
              <li>{{$t('HospitalityListEl1')}}</li>
              <!-- <li>{{$t('HospitalityListEl2')}}</li> -->
              <li>{{$t('HospitalityListEl3')}}</li>
              <li>{{$t('HospitalityListEl4')}}</li>
          </ul>
          <p class="para">{{$t('HospitalityPar1')}}</p>
          <p class="para">{{$t('HospitalityPar2')}}</p>
          <div class="links">
              <router-link to="/contact" class="link link-pink animate" >{{$t('ContactUs')}}</router-link>
              <router-link to="/welcome" class="link animate" href="">{{$t('CheckOurProducts')}}</router-link>
          </div>
      </div>
  </div>
  <div class="cooperation-row target-3">
      <div class="cooperation-slider">
          <div class="slide slide3"></div>
      </div>
      <div class="text" data-aos="fade-left">
          <h3 class="title">{{$t('RealEstDevH3Title')}}</h3>
          <ul class="list">
              <li>{{$t('RealEstDevListEl1')}}</li>
              <li>{{$t('RealEstDevListEl2')}}</li>
              <li>{{$t('RealEstDevListEl3')}}</li>
              <li>{{$t('RealEstDevListEl4')}}</li>
          </ul>
          <p class="para">{{$t('RealEstDevPar1')}}</p>
          <p class="para">{{$t('RealEstDevPar2')}}</p>
          <div class="links">
              <router-link to="/contact" class="link link-pink animate">{{$t('ContactUs')}}</router-link>
              <router-link to="/welcome" class="link animate">{{$t('CheckOurProducts')}}</router-link>
          </div>
      </div>
  </div>
  <div class="cooperation-row cooperation-row-reversed target-4">
      <div class="cooperation-slider">
          <div class="slide slide4"></div>
      </div>
      <div class="text" data-aos="fade-right">
          <h3 class="title">{{$t('ShowroomsH3Title')}}</h3>
          <ul class="list">
              <!-- <li>{{$t('ShowroomsListEl1')}}</li> -->
              <li>{{$t('ShowroomsListEl2')}}</li>
              <li>{{$t('ShowroomsListEl3')}}</li>
              <li>{{$t('ShowroomsListEl4')}}</li>
          </ul>
          <p class="para">{{$t('ShowroomsPar1')}}</p>
          <div class="links">
              <router-link to="/contact" class="link link-pink animate">{{$t('ContactUs')}}</router-link>
              <router-link to="/welcome" class="link animate">{{$t('CheckOurProducts')}}</router-link>
          </div>
      </div>
  </div>
  <cookies>

  </cookies>
  <!-- <section class="home-products home-products-margin-bottom">
      <div class="content-1500">
          <a class="category-link animate" href="">{{$t('CheckAllProducts')}}</a>
          <h4 class="main-title">{{$t('NewH4')}}</h4>
          <p class="subtitle">{{$t('RecentlyAdded')}}</p>
          <div class="product-slider">
              <div class="product animate">
                  <span class="add-to-whishlist hover"></span>
                  <figure class="img">
                      <img src="assets/images/products/1.jpg" alt="BSG concept - Sample product"/>
                  </figure>
                  <div class="description">
                      <p class="name animate">Sao</p>
                      <p class="subname animate">{{$t('VelvetSofa2Seats')}}</p>
                  </div>
                  <a class="link" href=""></a>
              </div>
              <div class="product animate">
                  <span class="add-to-whishlist hover"></span>
                  <figure class="img">
                      <img src="assets/images/products/2.jpg" alt="BSG concept - Sample product"/>
                  </figure>
                  <div class="description">
                      <p class="name animate">Nix</p>
                      <p class="subname animate">{{$t('VelvetSofa2Seats')}}</p>
                  </div>
                  <a class="link" href=""></a>
              </div>
              <div class="product animate">
                  <span class="add-to-whishlist hover"></span>
                  <figure class="img">
                      <img src="assets/images/products/3.jpg" alt="BSG concept - Sample product"/>
                  </figure>
                  <div class="description">
                      <p class="name animate">Phoebe</p>
                      <p class="subname animate">{{$t('VelvetSofa2Seats')}}</p>
                  </div>
                  <a class="link" href=""></a>
              </div>
              <div class="product animate">
                  <span class="add-to-whishlist hover"></span>
                  <figure class="img">
                      <img src="assets/images/products/4.jpg" alt="BSG concept - Sample product"/>
                  </figure>
                  <div class="description">
                      <p class="name animate">Olivier</p>
                      <p class="subname animate">{{$t('VelvetSofa2Seats')}}</p>
                  </div>
                  <a class="link" href=""></a>
              </div>
              <div class="product animate">
                  <span class="add-to-whishlist hover"></span>
                  <figure class="img">
                      <img src="assets/images/products/5.jpg" alt="BSG concept - Sample product"/>
                  </figure>
                  <div class="description">
                      <p class="name animate">Nairobi</p>
                      <p class="subname animate">{{$t('VelvetSofa2Seats')}}</p>
                  </div>
                  <a class="link" href=""></a>
              </div>
          </div>
      </div>
  </section> -->
  </div>
</template>

<script>
import TheSlider from '../layout/TheSlider.vue';
import Cookies from '../layout/Cookies.vue';
export default {
  components: { TheSlider, Cookies },
  methods: {
    scrollTo(target) {
      const y = window.scrollY + document.querySelector(target).getBoundingClientRect().top -160;
      const x = 0;
      window.scrollTo(x,y);
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>

<style scoped>
  .slide1 {
    background: url('../../assets/images/cooperation-sliders/slider1-1.png') center center no-repeat;
    background-size:cover;
  }

  .slide2 {
    background: url('../../assets/images/cooperation-sliders/slider2-1.png') center center no-repeat;
    background-size:cover;
  }

  .slide3 {
    background: url('../../assets/images/cooperation-sliders/slider3-1.png') center center no-repeat;
    background-size:cover;
  }

  .slide4 {
    background: url('../../assets/images/cooperation-sliders/slider4-1.png?v=13052022') center center no-repeat;
    background-size:cover;
  }

  .slide5 {
    background:url('../../assets/images/slider/slide1.jpg?v=21042022') center center no-repeat;
    background-size:cover;
  }

  .slide6 {
    background:url('../../assets/images/slider/slide2.jpg?v=21042022') center center no-repeat;
    background-size:cover;
  }

  .slide7 {
    background:url('../../assets/images/slider/slide3.jpg?v=21042022') center center no-repeat;
    background-size:cover;
  }

  div section {
    width: 100%;
  }
</style>