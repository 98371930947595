<template>
  <section>
    <form @submit.prevent="filter">
      <el-row>
        <el-col>
          <span>{{$t('legs')}}:</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
        <el-select v-model="leg" class="m-2" :placeholder="$t('select')" size="large">
          <el-option
            v-for="item in legs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span>{{$t('colour')}}:</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-select v-model="colour" class="m-2" :placeholder="$t('select')" size="large">
            <el-option
              v-for="item in colours"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
      <span>{{$t('height')}}:</span>
      </el-row>
      <el-row>
      <el-slider v-model="height" range :max="202"> </el-slider>
      </el-row>
      <el-row>
      <span>{{$t('length')}}:</span>
      </el-row>
      <el-row>
      <el-slider v-model="length" range :max="400"> </el-slider>
      </el-row>
      <el-row>
      <span>{{$t('width')}}:</span>
      </el-row>
      <el-row>
      <el-slider v-model="width" range :max="322"> </el-slider>
      </el-row>
      <main-button class="search-button" @click="filter">{{$t('search')}}</main-button>
    </form>
  </section>
</template>

<script>
import { ref } from 'vue';
import MainButton from '../UI/MainButton.vue';

export default {
  components: { MainButton },
  data() {
    return {
      leg: '',
      colour: '',
      height: ref([0, 202]),
      width: ref([0,322]),
      length: ref([0,400]),
      legs: ref([
        {
          label: "Dark Wood",
          value: "Dark Wood",
        },
        {
          label: "Black Metal",
          value: "Black Metal",
        },
        {
          label: "Light Wood",
          value: "Light Wood",
        },
        {
          label: "Gold Metal",
          value: "Gold Metal",
        },
        {
          label: "Silver Metal",
          value: "Silver Metal",
        },
      ]),
      colours: ref([
        {
          label: "Beige",
          value: "Beige",
        },
        {
          label: "Green",
          value: "Green",
        },
        {
          label: "Yellow",
          value: "Yellow",
        },
        {
          label: "Red",
          value: "Red",
        },
        {
          label: "Purple",
          value: "Purple",
        },
        {
          label: "Blue",
          value: "Blue",
        },
        {
          label: "Petrol",
          value: "Petrol",
        },
        {
          label: "Grey",
          value: "Grey",
        },
        {
          label: "Black",
          value: "Black",
        },
        {
          label: "Pink",
          value: "Pink",
        },
        {
          label: "Brown",
          value: "Brown",
        },
        {
          label: "Mint",
          value: "Mint",
        },
        {
          label: "Multicolour",
          value: "Multicolour",
        },
        {
          label: "Orange",
          value: "Orange",
        },
        {
          label: "Turquoise",
          value: "Turquoise",
        },
        {
          label: "White",
          value: "White",
        }
      ]),
    };
  },
  methods: {
      filter() {
        this.$router.push({path: '/furnitures', query:
          {
            width_max: this.width[1],
            width_min: this.width[0],
            height_max: this.height[1],
            height_min: this.height[0],
            length_max: this.length[1],
            length_min: this.length[0],
            simplifiedColor: this.colour,
            legColor: this.leg,
            furnitureCategory: this.$router.currentRoute.value.query.furnitureCategory
          }
        });
      }
    }
}
</script>

<style scoped>
:root {
  --el-color-primary: green;
}

section {
  width: 100% !important;
  margin: auto;
  text-align: left;
  margin-top: 50px;
}

.el-select {
  width: 100%;
}

.el-select .el-input.is-focus .el-input__inner {
  box-shadow: 0 0 0 1px var('black') inset !important;
}

.el-slider {
  --el-slider-main-bg-color: #707070;
  display: block;
}

.el-slider {
  width: 80%;
  margin: auto;
  margin-top: 10px;
}

.search-button {
  display: block;
  width: 100%;
}

.el-row {
  margin-bottom: 7px;
}

.el-slider__bar {
 background-color: #707070 !important;
}

span {
  color: #707070;
  font-weight: 50;
  display: block;
}

</style>