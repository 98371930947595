<template>
<el-col :sm="8" :md="6" :xl="6">
    <div class="card">
        <transition name="fade">
          <add-to-cart :id="id"></add-to-cart>
        </transition>
        <router-link :to="furnitureDetailLink">
          <img :src="image">
        </router-link>
    </div>
    <div>
      <el-input-number v-if="quantity" class="quantity" v-model="qty" :min="1" :max="100" @change="handleChange" />
      <a href=""><h5>{{ family }}</h5></a>
      <p class="card-text">{{ title[$i18n.locale] }}</p>
    </div>
</el-col>
</template>

<script>
import AddToCart from '../UI/AddToCart.vue';
export default {
  components: {AddToCart},
  props: ['id', 'family', 'title', 'test', 'quantity', 'parentId', 'image'],
  emits: ['del'],
  data() {
    return {
      qty: 5,
    }
  },
  watch: {
    qty() {
      let cart = localStorage.cart;
      cart = JSON.parse(cart);
      cart.forEach(element => {
        if (element['id'] == this.id) {
          element['qty'] = this.qty;
        }
      });
      cart = JSON.stringify(cart);
      localStorage.cart = cart;
    },
  },
  mounted() {
    let cart = localStorage.cart;
    cart = JSON.parse(cart);
    cart.forEach(element => {
      if (element['id'] == this.id) {
        this.qty = element['qty'];
      }
    });
  },
  computed: {
    furnitureDetailLink() {
      return {name: 'furniture-detail', params: {productId: this.id}}
    },
  },
}
</script>

<style scoped>

.slide-fade-enter-active {
  opacity: 1;
  z-index: 10;
}

.slide-fade-leave-active {
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

a, p {
  text-decoration: none;
  color: grey;
}

/* .heart {
  position: relative;
  left: 20px;
  top: 20px;
  width: 29px;
  height: 40px;
  z-index: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.heart:hover {
  animation: shake 0.5s;
  -webkit-animation: shake 0.5s;
  -moz-animation: shake 0.5s;
}

.heart-clicked {
  background: url('../../assets/wishlist_blue_2.png') center center no-repeat;
  background-size:27px 40px;
}

.heart-not-clicked {
  background:url('../../assets/images/decors/whishlist.png') center center no-repeat;
  background-size:27px 40px;
} */

.card {
  border: 0;
  box-shadow: 2px 2px 15px 1px #dfdfdf;
  margin-top: 10px;
  margin-bottom: 10px;
}

img {
  width: 100%;
  top: -20px;
  position: relative;
}

.quantity{
  display: block !important;
  margin-top: 5px !important;
  margin:auto;
}
</style>