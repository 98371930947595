<template>
  <section>
    <router-link :to="furnitureDetailLink" @click="handleCard">
      <div class="color-card">
        <div class="left">
          <img :src="image" class="card-img-top">
        </div>
        <div class="right">
            <h5>{{variant.family}}</h5>
            <p>{{title[$i18n.locale]}}</p>
            <p>{{$t('colour')}}: {{variant.color[$i18n.locale]}}</p>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  props: ["variant", "title", "id", "image"],
  emits: ['showSlideShow'],
  computed: {
      furnitureDetailLink() {
        return {name: 'furniture-detail', params: {productId: this.id}}
      },
    },
  methods: {
    handleCard() {
      this.$emit('showSlideShow');
    }
  }
}
</script>

<style scoped>
  section {
    border: 0;
    box-shadow: 2px 2px 15px 1px #dfdfdf;
    width: 95%;
    margin: 10px auto;
  }

  .color-card {
    display: flex;
  }

  .left {
    width: 45%;
  }

  a {
    text-decoration: none;
  }

  .right {
    width: 50%;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    padding-top: 25px;
  }


  p {
    padding-top: 10px;
    color: #676767;
  }

  .items {
    position: absolute;
    top: 60px;
    left: 20px;
  }
</style>