<template>
    <div class="subpage subpage-contact">
        <div class="content-1500">
            <h3 class="title">BSG Pro</h3>
            <h3 class="subtitle">{{$t('GDPRSubtitle')}}</h3>
            <h3 class="gdpr-title">{{$t('GDPRTitle1')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText1')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle2')}}</h3>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle1')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText2')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle2')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText3')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle3')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText4')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle4')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText5')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle5')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText6')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle6')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText7')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle7')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText8')}}</p>
            <h4 class="gdpr-subtitle">{{$t('GDPRSubtitle8')}}</h4>
            <p class="gdpr-text">{{$t('GDPRText9')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle3')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText10')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle4')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText11')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle5')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText12')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle6')}}</h3>
            <p class="gdpr-text" style="font-style: italic;">{{$t('GDPRText13')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle7')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText14')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle8')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText15')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle9')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText16')}}</p>
            <h3 class="gdpr-title">{{$t('GDPRTitle10')}}</h3>
            <p class="gdpr-text">{{$t('GDPRText17')}}</p>
        </div>
    </div>
</template>

<style scoped>
    .title, .subtitle {
        display: block;
        font-size: 40px;
    }
    .title {
        color: grey;
        margin-bottom: 10px;
    }
    .subtitle:after {
        content: "";
        display: block;
        width: 150px;
        height: 1px;
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: #6A7078;
    }
    .gdpr-title {
        margin-bottom: 25px;
        line-height: 1.5;
        font-size: large;
    }
    .gdpr-subtitle {
        margin-bottom: 15px;
        line-height: 2;
        color: grey;
    }
    .gdpr-text {
        margin-bottom: 50px;
        line-height: 1.5;
        white-space: pre-line;
    }
</style>