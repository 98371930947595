<template>
  <div>
    <h1 class="display-4"> {{ $t('measurements')}} </h1>
    <hr>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" v-for="(variant,key) in variants.measurements[$i18n.locale]" :key="variant.id">
       {{key}}: {{variant}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['variants'],
}
</script>

<style scoped>
hr {
    border-color: #697079;
    background-color: #697079;
    color: #697079;
}
</style>