<template>
  <div>
    <h1 class="display-4">{{ $t('fabricDesc') }}</h1>
    <hr>
    <p>{{variants.variants[0].fabricDescription[$i18n.locale]}}</p>
  </div>
</template>

<script>
export default {
  props: ['variants'],
}
</script>

<style scoped>
.mtop {
  margin-top: 5%;
}

hr {
    border-color: #697079;
    background-color: #697079;
    color: #697079;
}
</style>