<template>
  <section v-loading="loading">
    <div class="product-detail">
      <div class="left">
        <component :is="leftBox" @showComponent="showComponent" @rerender="forceRerender" class="leftbox" :variants="product"></component>
      </div>
      <div class="right">
        <div class="info">  
          <add-to-cart v-if="renderHeart" :id="$route.params.productId" class="add-to-cart"></add-to-cart>
          <h1 class="display-4">{{ product.family }}</h1>
          <hr>
          <p class="lead">{{product.title[$i18n.locale]}}</p>
          <main-button @click="measurementsSet">{{$t('measurements')}}</main-button>
          <main-button @click="fabricDescriptionSet">{{$t('fabricDesc')}}</main-button>
          <main-button @click="colorsSet">{{$t('moreColours')}}</main-button>
          <p class="lead">Reference: {{ reference }}</p>
          <p class="lead" @click="filterForFabrics">Fabric reference: {{fabric}}</p>
          <p class="lead">Color: {{color}}</p>
          <p class="lead">Fabric type: {{product.fabricType}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainButton from '../UI/MainButton.vue';
import VariantColors from './product-detail/VariantColors.vue';
import SlideShow from './product-detail/SlideShow.vue';
import ProductMeasurements from './product-detail/ProductMeasurements.vue';
import FabricDescription from './product-detail/FabricDescription.vue';
import { mapGetters } from 'vuex'; 
import AddToCart from '../UI/AddToCart.vue';

export default {
  components: { MainButton, VariantColors, SlideShow, ProductMeasurements, FabricDescription, AddToCart },
  data() {
    return {
      product: 'null',
      leftBox: "slide-show",
      loading: true,
      reference: 'null',
      color: 'null',
      fabric: 'null',
      fabricType: 'null',
      renderHeart: true,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  watch: {
    '$router.currentRoute.value.fullPath'() {
      const id = this.$route.params.productId;
      this.axios.get(this.apiLink + "variants/" + id).then(function(response) {
        return response.data;
      }).then((data) => {
        this.reference = data.reference;
        this.color = data.color[this.$i18n.locale];
       this.fabric = data.fabricReference;
        const parentId = data.parentId;
        this.axios.get(this.apiLink + "furnitures/" + parentId).then(function(response) {
          return response.data;
        }).then((data) => {
          this.product = data;
          this.loading = false;
        });
      });
    }
  },
  methods: {
    colorsSet() {
      this.leftBox == 'variant-colors' ? 
      this.leftBox = 'slide-show': 
      this.leftBox = "variant-colors";
    },
    measurementsSet() {
      this.leftBox == 'product-measurements' ? 
      this.leftBox = 'slide-show': 
      this.leftBox = 'product-measurements';
    },
    fabricDescriptionSet() {
      this.leftBox == 'fabric-description' ?
      this.leftBox = 'slide-show':
      this.leftBox = 'fabric-description';
    },
    showComponent(component) {
      this.leftBox = component;
    },
    filterForFabrics() {
      this.$router.push({path: '/furnitures', query:
          {
            fabricReference: this.fabric,
          }
        });
    },
    forceRerender() {
      console.log("test rerenderu");
      this.renderHeart = false;
      this.$nextTick(() => {
        this.renderHeart = true;
      });
    }
  },
  created() {
    const id = this.$route.params.productId;
    this.axios.get(this.apiLink + "variants/" + id).then(function(response) {
      return response.data;
    }).then((data) => {
      this.reference = data.reference;
      this.color = data.color[this.$i18n.locale];
      this.fabric = data.fabricReference;
      const parentId = data.parentId;
      console.log("XD");
        console.log(data.parentId);
      this.axios.get(this.apiLink + "furnitures/" + parentId).then(function(response) {
        return response.data;
      }).then((data) => {
        this.product = data;
        this.fabricType = data.fabricType;
        this.loading = false;
      });
    });
  }
}
</script>

<style scoped>
section {
  width: 100%;
  min-height: 85vh;
}

.lead {
  font-size: 0.9rem;
  margin: 4px;
}

.product-detail {
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  padding: 200px 0;
}

.add-to-cart {
  position: absolute;
  left: 90%;
}

.left {
  width: 60%;
}

.right {
  width: 40%;
  text-align: left;
}

.info {
  width: 60%;
  position: relative;
}

@media only screen and (max-width: 1100px) {
.left, .right {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    margin: auto;
}

.info {
  width: 100%;
  padding: 20px;
}

.product-detail {
  display: block;
}
}



.leftbox {
  width: 70%;
  margin: auto;
  z-index: 100;
  background-color: white;
  /* margin-top: 10vh; */
}

hr {
    border-color: #697079;
    background-color: #697079;
    color: #697079;
}
button {
  width: 100%;
}
</style>