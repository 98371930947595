<template>
<div class="welcome-bg">
  <div class="welcome-slider">
      <div class="slide slide5"></div>
      <div class="slide slide6"></div>
      <div class="slide slide7"></div>
  </div>
  <div class="content-1500">
      <div class="welcome-title">
          <p class="title color-blackv" :data-text="$t('SliderWhoWeAre')">BSG <span class="pro">pro</span></p>
          <hr class="spacer">
          <p class="text">{{$t('SliderTxt')}}</p>
      </div>
  </div>
  <div class="items">
      <div class="item animate hover-1 scroll-to" @click="scrollTo('.target-1')" data-target="target-1">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-architects.png" alt="BSG concept - Architects"/>
              <h4 class="title color-black">{{$t('SliderH41')}}</h4>
          </div>
      </div>
      <div class="item animate hover-2 scroll-to" @click="scrollTo('.target-3')" data-target="target-3">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-estate-developers.png" alt="BSG concept - Estate developers"/>
              <h4 class="title color-black">{{$t('SliderH42')}}</h4>
          </div>
      </div>
      <div class="item animate hover-3 scroll-to" @click="scrollTo('.target-2')" data-target="target-2">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-hospitality.png" alt="BSG concept - Hospitality"/>
              <h4 class="title color-black">{{$t('SliderH43')}}</h4>
          </div>
      </div>
      <div class="item animate hover-4 scroll-to" @click="scrollTo('.target-4')" data-target="target-4">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-showrooms.png" alt="BSG concept - Showrooms and shops"/>
              <h4 class="title color-black">{{$t('SliderH44')}}</h4>
          </div>
      </div>
  </div>
</div>
</template>

<script>
import $ from 'jquery';
import AOS from "aos";
import 'slick-carousel';
export default {
  emits: ['scroll'],
  methods: {
    scrollTo(target) {
      this.$emit('scroll', target);
    }
  },
  mounted() {
    $('.welcome-slider').slick({
      infinite: true,
      dots: false,
      arrows: false,
      speed: 1200,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      draggable: false,
      swipe: false,
    });
    AOS.init({
        disable: 'mobile',
        once: true
    });
  }
}
</script>

<style scoped>
.slide5 {
  background:url('../../assets/images/slider/slide1.jpg?v=21042022') center center no-repeat;
  background-size:cover;
}

.slide6 {
  background:url('../../assets/images/slider/slide2.jpg?v=21042022') center center no-repeat;
  background-size:cover;
}

.slide7 {
  background:url('../../assets/images/slider/slide3.jpg?v=21042022') center center no-repeat;
  background-size:cover;
}
</style>