<template>
  <section class="product-list-section" v-loading="loading">
    <div class="product-list">
      <div class="left">
        <div class="fixed-left">
          <transition name="slidedown">
            <categories-pannel v-if="!isShow"></categories-pannel>
          </transition>
          <transition name="slidedown">
            <the-filter v-if="isShow"></the-filter>
          </transition>
          <main-button @click="isShow = !isShow" class="filter-button">
            <span v-if="!isShow">{{$t('filter')}}</span>
            <span v-if="isShow">{{$t('Close')}}</span>
          </main-button>
        </div>
      </div>
      <div class="right">
        <el-row :gutter="20" v-infinite-scroll="scroll">
          <product-card
          v-for="(product,id) in products" 
          :key="id"
          :id="product.variants[product.photoNumber]['id']"
          :parentId="product.id"
          :family="product.family" 
          :title="product.title"
          :image="product.variants[product.photoNumber]['photos'][0]['image']"
          :quantity="false"
          >
          </product-card>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
import CategoriesPannel from './CategoriesPannel.vue';
import ProductCard from './../UI/ProductCard.vue';
import TheFilter from './../layout/TheFilter.vue';
import MainButton from '../UI/MainButton.vue';

export default {
  components: {
    CategoriesPannel,
    ProductCard,
    TheFilter,
    MainButton,
  },
  data() {
    return {
      next: "",
      products: [],
      isShow: false,
      loading: true,
    };
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    '$router.currentRoute.value.fullPath'() {
      this.getProducts();
    },
  },
  methods: {
    getProducts() {
      this.loading = true;
      var query = "";
      if (typeof(this.$router.currentRoute.value.query.search) != "undefined") {
        query = "furnitures/?search=" + this.$router.currentRoute.value.query.search;
      }
      else {
        const params = new URLSearchParams(this.$router.currentRoute.value.query);
        query = "furnitures-filter/?" + params; 
      }
      const api = this.apiLink + query;
      this.axios.get(api).then(function(response) {
        return response.data;
      }).then((data) => {
        this.products = data.results;
        for (var z = 0; z < this.products.length; z++) {
          this.products[z]['photoNumber'] = 0;
        }
        if (query == "furnitures-filter/?" + new URLSearchParams(this.$router.currentRoute.value.query)) {
          for (var i = 0; i < this.products.length; i++) {
            for (var c = 0; c < this.products[i].variants.length; c++) {
                if (this.products[i].variants[c].simplifiedColor == this.$router.currentRoute.value.query.simplifiedColor) {
                  this.products[i]['photoNumber'] = c;
                }
            }
          }
        }     
        this.next = data.next;
        this.loading = false;
      });
    },
    scroll() {
      if (this.next) {
        const api = this.next;
        this.axios.get(api).then(function(response) {
          return response.data;
        }).then((data) => {
          data.results.forEach(el => {
            var el2 = el;
            el2['photoNumber'] = 0;
            if (this.$router.currentRoute.value.query.simplifiedColor != undefined) {
              for (var c = 0; c < el2.variants.length; c++) {
                  if (el2.variants[c].simplifiedColor == this.$router.currentRoute.value.query.simplifiedColor) {
                    el2['photoNumber'] = c;
                  }
              }
            }
            // console.log(el2);
            this.products.push(el2)
            // console.log(this.products);
            });
          this.next = data.next;
        });
      }
    }
  }
}
</script>

<style scoped>

.product-list-section {
  width: 100%;
  margin-top: 130px;
  min-height: 90vh;
}

.product-list {
  display: flex;
}

.left {
  width: 20%;
}

.right {
  width: 78%;
}

.fixed-left {
  position: sticky;
  top: 130px;
  padding: 10px 40px;
}

.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}

.filter-button {
  width: 100%;
}


@media only screen and (max-width: 1100px) {
  .right {
    width: 100%;
  }

  .left {
    display: none;
  }
  .product-list-section {
    margin-top: 200px;
  }
}
</style>