<template>
  <section class="wishlist">
    <main-button @click="clearCart">Clear</main-button>
    <a :href="downloadLink"><main-button>Download excel</main-button></a>
    <h1 v-if="products === null">No products here.</h1>
    <el-row :gutter="30">
      <product-card
        v-for="(product,id) in products" 
        :key="id"
        :id="product.id"
        :parentId="product.parentId"
        :family="product.family" 
        :title="product.title"
        :image="product['photos'][0]['image']"
        @del="deleteFromProducts"
        quantity="true">
      </product-card>
    </el-row>
  </section>
</template>

<script>
import MainButton from '../UI/MainButton.vue';
import ProductCard from '../UI/ProductCard.vue';
export default {
  data() {
    return {
      products: null,
      downloadLink: null
    };
  },
  components: { ProductCard, MainButton },
  methods: {
    clearCart() {
      localStorage.cart = JSON.stringify([]);
      this.products = null;
    },
    deleteFromProducts(id) {
      this.products.forEach(element => {
        console.log(element.id);
        if (id == element.id) {
           var index = this.products.findIndex(function(i) {
            return i.id === element.id;
          });
          this.products.splice(index,1);
        }
      });
      console.log(this.products);
    }
  },
  mounted() {
    //const params = new URLSearchParams(this.$router.currentRoute.value.query);
    if (localStorage.cart != '') {
      let downloadLink = this.apiLink + "download-excel/";
      let variables = "";
      const cart = JSON.parse(localStorage.cart);
      cart.forEach(element => {
        variables += element['id'] + ",";
        downloadLink += element['id'] + ",";
      });
      downloadLink += "/";
      cart.forEach(element => {
        downloadLink += element['qty'] + ",";
      });
      this.downloadLink = downloadLink;
      console.log(variables);
      if (variables != "" ) {
        const api = this.apiLink + "variants/?id__in=" + variables;
        this.axios.get(api).then(function(response) {
          return response.data;
        }).then((data) => {
          this.products = [];
          data.results.forEach(el => this.products.push(el));
          this.next = data.next;
      });
      }
    }
  },
}
</script>

<style scoped>
.el-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

section {
  width: 70%;
  margin: auto;
}

.wishlist {
  margin-top: 150px;
  min-height: 80vh;
}
</style>