<template>
  <footer class="footer">
      <div class="content-1500">
          <div class="col">
              <a class="link" href="">GDPR</a>
              <router-link class="link" to="/cooperation">Cooperation</router-link>
              <router-link class="link" to="/contact">Contact</router-link>
          </div>
          <div class="col">
              <div class="text">
                  <p class="para">Luxobesolux Sarl</p>
                  <p class="para">8 rue de Beggen</p>
                  <p class="para">L-1220 Luxembourg</p>
                  <p class="para">+33 988 770 133</p>
              </div>
              <div class="text">
                  <p class="para">Beso Lux Sp. z o.o.</p>
                  <p class="para">Ul. Łąkowa 7a/E</p>
                  <p class="para">90-562 Łódź</p>
                  <p class="para">NIP: 7292718480</p>
              </div>
          </div>
      </div>
      <div class="bottom">
          <p class=text>2022 by Besolux group</p>
      </div>
  </footer>  
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    display: block;
}
</style>