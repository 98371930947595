<template>
<header class="header">
  <div class="header-top">
      <div class="content-95">
          <span class="text">Special offers for business! <router-link class="animate" to="/contact">Contact now</router-link></span>
          <div class="locale hover toggle-locale-select">
              <img class="flag" :src="currentLanguage['src']" alt="flag"/>
              <!-- <img class="flag" src="../../assets/images/decors/en.png" alt="flag"/> -->
              <span class="code">{{ currentLanguage['text'] }}</span>
              <div class="locale-select">
                  <a class="item" @click="changeLanguage('en')">
                      <span class="text">English</span>
                      <img class="icon" src="../../assets/images/decors/en.png" alt="flag"/>
                  </a>
                  <a class="item" @click="changeLanguage('fr')">
                      <span class="text">Français</span>
                      <img class="icon" src="../../assets/images/decors/fr.png" alt="flag"/>
                  </a>
                  <a class="item" @click="changeLanguage('de')">
                      <span class="text">Deutsch</span>
                      <img class="icon" src="../../assets/images/decors/de.png" alt="flag"/>
                  </a>
                  <a class="item" @click="changeLanguage('pl')">
                      <span class="text">Polski</span>
                      <img class="icon" src="../../assets/images/decors/pl.png" alt="flag"/>
                  </a>
              </div>
          </div>
      </div>
  </div>
  <div class="header-main">
      <div class="content-95">
          <figure class="logo">
              <router-link to="/">
                  <img class="flag" src="../../assets/images/logo.png" alt="BSG concept - logo"/>
              </router-link>
          </figure>
          <nav class="nav">
              <router-link to="/" :class="{item: true, animate:true,  active: $route.path == '/'}">{{$t('MenuElAbout')}}</router-link>
              <router-link :class="{item: true, animate:true,  active: $route.path == '/cooperation'}" to="/cooperation">{{$t('MenuElBusiness')}}</router-link>
              <router-link :class="{item: true, animate:true,  active: $route.path == '/welcome'}" to="/welcome">{{$t('MenuElProducts')}}</router-link>
              <router-link :class="{item: true, animate:true,  active: $route.path == '/contact'}" to="/contact">{{$t('MenuElContact')}}</router-link>
          </nav>
          <div>
            <router-link to="/wishlist" class="wishlist">
              <main-button class="heart-button"><div class="heart"></div><span class="wishlist-text">{{ $t('wishlist') }}</span></main-button>
            </router-link>
            <!-- <main-button @click="showComponent('login-form')" id="account">Account</main-button>
            <router-link to="/admin" v-if="isAdmin"> <main-button>Admin</main-button> </router-link> -->
            <form @submit.prevent="acceptInput" class="search-form" action="" method="get">
                <input class="animate center-vertically hover" type="submit" value=" " />
                <input v-model="searchValue" class="animate" type="text" name="query" :placeholder="$t('search')" required/>
            </form>
          </div>
          <figure class="magnifier hover">
              <img src="../../assets/images/decors/magnifier.png" alt="menu icon"/>
          </figure>
          <figure class="res-menu hover">
              <img src="../../assets/images/decors/menu.png" alt="menu icon"/>
          </figure>
      </div>
  </div>
  <component @close="showComponent" :is="account"></component>
</header>
</template>
<script>
//import SearchInput from '../UI/SearchInput.vue'
import $ from 'jquery';
import MainButton from '../UI/MainButton.vue';
// import LoginForm from '../layout/authentication/LoginForm.vue';
// import AdminPannel from './admin/AdminPannel.vue';
import { mapGetters } from 'vuex'; 
export default {
  components: { MainButton },// LoginForm, AdminPannel },
  //components:  { SearchInput },
  data () {
    return {
      wishlistActive: false,
      searchValue: null,
      account: '',
      currentLanguage: {
        "src": require("../../assets/images/decors/en.png"),
        "text": "EN",
      },
      langs: {
        "en": {
          "src": require("../../assets/images/decors/en.png"),
          "text": "English"
        },
        "fr": {
          "src": require("../../assets/images/decors/fr.png"),
          "text": "Français",
        },
        "de": {
          "src": require("../../assets/images/decors/de.png"),
          "text": "Deutsch",
        },
        "pl": {
          "src": require("../../assets/images/decors/pl.png"),
          "text": "Polski",
        }
      },
    }
  },
  methods: {
    acceptInput() {
      this.$router.push({path: '/furnitures', query: {search: this.searchValue}});
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.currentLanguage = this.langs[lang];
    },
    showComponent(component) {
      this.account = component;
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.changeLanguage(this.$i18n.locale);
    $(window).on('load', function () {
        $('#loader').hide();
    });

    $(document).ready(function(){
        $('.toggle-locale-select').click(function(){
            $('.locale-select').toggle();
        });
        $('.res-menu').click(function(){
            $('.header .header-main .nav').toggle();
            $('.search-form').hide();
        });
        $('.nav').click(function(){
          if (window.innerWidth < 1100) {
            $('.header .header-main .nav').hide();
          }
        });
        $('.magnifier').click(function(){
            $('.search-form').toggle();
            $('.header .header-main .nav').hide()
            
        });
    })
  },
  watch: {
    '$i18n.locale'(newLang) {
      localStorage.lang = newLang;
      // localStorage.setItem("lang", newLang);
      
    },
    '$route.path'() {
      if (["/","/cooperation","/contact"].includes(this.$route.path)) {
          this.wishlistActive = false;
        }
        else {
          this.wishlistActive = true;
        }
      }
  }
}
</script>

<style scoped>

.wishlist, .search-form {
  display: inline-block;
  position: relative;
}

.nav {
  position: absolute;
  left: 50%;
  top: 105px;
  transform: translateX(-50%) translateY(-50%);
  /* top: 100px;
  transform: translate(-50%, -50%); */
}

.wishlist-text {
  padding-left: 17px;
}

.heart {
  display: inline-block;
  width: 15px;
  height: 14px;
  background: url('../../assets/images/WISHLIST_HEART.png') center center no-repeat;
  background-size: 15px 14px;
  position: absolute;
  left: 14px;
}

@media only screen and (max-width: 1100px) {
  .wishlist {
    position: absolute;
    top: -3px;
    right: 120px;
  }

  .wishlist-text {
    display: none;
  }
  .heart {
    position: relative;
    width: 35px;
    height: 34px;
    background-size: 35px 34px;
  }

  .heart-button {
    border: 0;
    padding: 0;
  }

  .heart-button:hover {
    background-color: white;
    border: 0;
  }

  .header .header-main .nav {
    z-index: 100;
  }
}

</style>