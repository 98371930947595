import { createStore } from 'vuex';
import axios from 'axios';
const apiLink = "https://api.bsg-pro.com/";

const store = createStore({
  state() {
    return {
      apiLink: "",
      token: '',
      isAuth: false,
      isAdmin: false,
    };
  },
  mutations: {
    register(state, payload) {
      console.log(state);
      console.log(payload);
    },
    authenticate(state) {
      state.isAuth = true;
    },
    logout(state) {
      state.isAuth = false;
      state.isAdmin = false;
      localStorage.token = '';
    },
    checkToken(state) {
      const api = apiLink + 'check-token';
      const token = localStorage.token;
      axios.get(api, {headers: {
        Authorization: `Token ${token}`
      }}).then (function(response) {
          state.isAuth = true;
          state.isAdmin = response.data.is_admin;
      });
    }
  },
  actions: {
    authenticate(context, user) {
      const api = apiLink + 'api-token-auth/';
      axios.post(api, {
        username: user.username,
        password: user.password
      })
      .then(function (response) {
        localStorage.token = response.data.token;
        context.commit('authenticate');
        context.commit('checkToken');
      })
    },
    async register(context, user) {
      const api = apiLink + 'register/';
      var registerUser = {
        username: user.username,
        password: user.password,
        password2: user.password2,
        first_name: user.fullname,
        last_name: user.fullname,
        company: user.company,
        profile_type: user.profile_type,
        country: user.country,
        province: user.province,
        city: user.city,
        phone: user.phone,
        email: user.email
      }
      try {
        var response = await axios.post(api, registerUser);
        return await response;
      } catch (err) {
        return await err.response;
      }
    },
    logout(context) {
      context.commit('logout');
    },
    checkToken(context) {
      context.commit('checkToken');
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuth;
    },
    isAdmin(state) {
      return state.isAdmin;
    }
  }
});

export default store;