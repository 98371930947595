<template>
<div class="subpage">
  <the-slider-business></the-slider-business>
  <div class="cooperation-grid">
      <div class="item animate item-1">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-architects.png" alt="BSG concept - Architects"/>
              <h4 class="title" data-aos="fade-up">{{$t('AboutCoopGrid1h4')}}</h4>
              <p class="text" data-aos="fade-up">{{$t('AboutCoopGrid1p')}}</p>
          </div>
      </div>
      <div class="item animate item-2">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-estate-developers.png" alt="BSG concept - Estate developers"/>
              <h4 class="title" data-aos="fade-up">{{$t('AboutCoopGrid2h4')}}</h4>
              <p class="text" data-aos="fade-up">{{$t('AboutCoopGrid2p')}}</p>
          </div>
      </div>
      <div class="item animate item-3">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/hospitality.png" alt="BSG concept - Hospitality"/>
              <h4 class="title" data-aos="fade-up">{{$t('AboutCoopGrid3h4')}}</h4>
              <p class="text" data-aos="fade-up">{{$t('AboutCoopGrid3p')}}</p>
          </div>
      </div>
      <div class="item animate item-4">
          <div class="center-vertically">
              <img class="icon" data-aos="fade-up" src="../../assets/images/decors/cooperation-showrooms.png" alt="BSG concept - Showrooms and shops"/>
              <h4 class="title" data-aos="fade-up">{{$t('AboutCoopGrid4h4')}}</h4>
              <p class="text" data-aos="fade-up">{{$t('AboutCoopGrid4p')}}</p>
          </div>
      </div>
  </div>
  <div class="cooperation-icons-wrapper cooperation-icons-no-margin-bottom">
      <div class="content-1500">
          <div class="cooperation-icons">
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-3d.png" alt="BSG concept - 3D models"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon1txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-customization.png" alt="BSG concept - Customization"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon2txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-single-point.png" alt="BSG concept - Single point of contact"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon3txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-no-minimum-order.png" alt="BSG concept - No minimum order required"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon4txt')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/cooperation-consultancy.png" alt="BSG concept - Interior design consultancy"/>
                  </figure>
                  <p class="title">{{$t('CoopIcon5txt')}}</p>
              </div>
          </div>
      </div>
  </div>
  <section class="home-intro">
      <div class="content-1500">
          <div class="text" data-aos="fade-right">
              <h3 class="title">{{$t('AboutHomeH3')}}</h3>
              <p class="para">{{$t('AboutHomep1')}}</p>
              <p class="para">{{$t('AboutHomep2')}}</p>
              <p class="para">{{$t('AboutHomep3')}}</p>
              <p class="para">{{$t('AboutHomep4')}}</p>
              <router-link to="/welcome" class="link">{{$t('CheckOurProducts')}}</router-link>
          </div>
          <div class="video" data-aos="fade-left">
              <video loop muted playsinline autoplay>
      <source src="../../assets/video/video.mp4" type="video/mp4">
    </video>
          </div>
      </div>
  </section>
  <section class="home-process">
      <div class="content-1500">
          <div class="icons">
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/production.png" alt="BSG concept - Production"/>
                  </figure>
                  <p class="title">{{$t('AboutProcess1')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/graphic-service.png" alt="BSG concept - Graphic and marketing service"/>
                  </figure>
                  <p class="title">{{$t('AboutProcess2')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/international-sales.png" alt="BSG concept - International Sales"/>
                  </figure>
                  <p class="title">{{$t('AboutProcess3')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/delivery.png" alt="BSG concept - Delivery, dropshipping and logistics support"/>
                  </figure>
                  <p class="title">{{$t('AboutProcess4')}}</p>
              </div>
              <span class="arrow" data-aos="fade-left"></span>
              <div class="item" data-aos="fade-left">
                  <figure class="icon">
                      <img src="../../assets/images/decors/customer-care.png" alt="BSG concept - Customer Care"/>
                  </figure>
                  <p class="title">{{$t('AboutProcess5')}}</p>
              </div>
          </div>
          <div class="description">
              <img class="img" src="../../assets/images/how-we-work.png" data-aos="fade-right" alt="BSG concept - How do we work?"/>
              <div class="text" data-aos="fade-left">
                  <h3 class="title">{{$t('AboutHowDoWeWorkH3')}}</h3>
                  <p class="para">{{$t('AboutHowDoWeWorkp1')}}</p>
                  <p class="para">{{$t('AboutHowDoWeWorkp2')}}</p>
                  <p class="para">{{$t('AboutHowDoWeWorkp3')}}</p>
                  <p class="para">{{$t('AboutHowDoWeWorkp4')}}</p>
              </div>
          </div>
      </div>
  </section>
  <section class="home-grid-right">
      <div class="grid-content">
          <div class="item">
              <div class="center-vertically">
                  <h4 class="title" data-aos="fade-up">{{$t('AboutHomeGrid1H4')}}</h4>
                  <p class="text" data-aos="fade-up">{{$t('AboutHomeGrid1p')}}</p>
              </div>
          </div>
          <div class="item item-img image2"></div>
          <div class="item item-img image3"></div>
          <div class="item item-dark-gray">
              <div class="center-vertically">
                  <h4 class="title" data-aos="fade-up">{{$t('AboutHomeGrid2H4')}}</h4>
                  <p class="text" data-aos="fade-up">{{$t('AboutHomeGrid2p')}}</p>
                  <img class="icon" data-aos="fade-up" src="../../assets/images/max.png" alt="max icon"/>
              </div>
          </div>
      </div>
      <figure class="paroller bg-image" data-paroller-factor="0.08"></figure>
  </section>
  <section class="home-text-col">
      <div class="content-1500">
          <div class="col" data-aos="fade-right">
              <h3 class="title">{{$t('AboutHomeText1h3')}}</h3>
              <p class="para">{{$t('AboutHomeText1p1')}}</p>
              <p class="para">{{$t('AboutHomeText1p2')}}</p>
              <div class="nav">
                  <router-link to="/contact" class="link">{{$t('AboutHomeTextLink')}}</router-link>
              </div>
          </div>
          <div class="col" data-aos="fade-left">
              <h3 class="title">{{$t('AboutHomeText2h3')}}</h3>
              <p class="para">{{$t('AboutHomeText2p1')}}</p>
              <p class="para">{{$t('AboutHomeText2p2')}}<br>{{$t('AboutHomeText2p3')}}</p>
              <div class="nav">
                  <router-link to="/welcome" class="link" href="">{{$t('AboutHomeTextLink2')}}</router-link>
              </div>
          </div>
      </div>
  </section>
  <section class="home-grid-left">
      <figure class="paroller bg-image" data-paroller-factor="0.08"></figure>
      <div class="grid-content">
          <div class="item item-beige">
              <div class="center-vertically">
                  <h4 class="title" data-aos="fade-up">{{$t('AboutHome2Grid1H4')}}</h4>
                  <p class="text" data-aos="fade-up">{{$t('AboutHome2Grid1p')}}</p>
              </div>
          </div>
          <div class="item item1"></div>
          <div class="item item2"></div>
          <div class="item">
              <div class="center-vertically">
                  <h4 class="title" data-aos="fade-up">{{$t('AboutHome2Grid2H4')}}</h4>
                  <p class="text" data-aos="fade-up">{{$t('AboutHome2Grid2p')}}</p>
              </div>
          </div>
      </div>
  </section>
</div>
</template>

<script>
import TheSliderBusiness from '../layout/TheSliderBusiness.vue';
export default {
  components: {TheSliderBusiness},
  mounted() {
    const y = window.scrollY + document.querySelector(".cooperation-grid").getBoundingClientRect().top -160;
    console.log(y);
    const x = 0;
    window.scrollTo(x,y);
    },
}
</script>

<style scoped>
  .image1 {
    background:url('../../assets/images/slider/slide4.png?v=21042022') center center no-repeat;
    background-size:cover;
  }

  .image2 {
    background:url('../../assets/images/stylish-collections.png') center center no-repeat;
    background-size:cover;
  }

  .image3 {
    background:url('../../assets/images/materials-for-professionals.png') center center no-repeat;
    background-size:cover;
  }

  .item1 {
    background:url('../../assets/images/personalized-offers.png') center center no-repeat;
    background-size:cover;
  }

  .item2 {
    background:url('../../assets/images/customer-service.png') center center no-repeat;
    background-size:cover;
  }

  .cooperation-grid .item:hover {
    cursor: default;
  }

</style>