<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
        <div class="modal-window" @click.stop>
            <h6 class="modal-title">{{$t('CookiesTitle1')}}</h6>
            <p class="modal-text">{{$t('CookiesText1')}}</p>
            <h6 class="modal-subtitle">{{$t('CookiesTitle2')}}<el-switch class="modal-switch" v-model="value1" style="--el-switch-on-color:#000000;"/></h6>
            <p class="modal-text">{{$t('CookiesText2')}}</p>
            <h6 class="modal-subtitle">{{$t('CookiesTitle3')}}<el-switch class="modal-switch" v-model="value2" style="--el-switch-on-color:#000000;"/></h6>
            <p class="modal-text">{{$t('CookiesText3')}}</p>
            <hr class="modal-bar">
            <main-button class="modal-button" @click="setCookies">{{$t('CookiesAccept')}}</main-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods:{
            setCookies(){
                // this.$cookies.set('cookies', 'cookies', '1d');
                this.$emit('close-modal');
            }
        },
        data() {
            return {
                showModal: true,
                value1: true,
                value2: true
            };
        }
    };
</script>

<script setup>
    import { ref } from 'vue';
    const value1 = ref(true)
    const value2 = ref(true)
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: rgba(0,0,0,0.5);
    }
    .modal-window {
        text-align: left;
        background-color: white;
        height: auto;;
        width: 500px;
        margin: auto
    }
    .modal-title {
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
        font-family: HelveticaBold;
        font-size: 1.25rem;
    }
    .modal-subtitle {
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
        font-family: HelveticaBold;
    }
    .modal-text {
        margin-left: 5%;
        margin-right: 5%;
        line-height: 1.5;
    }
    .modal-switch {
        margin-left: 5%;
        float: right;
    }
    .modal-bar {
        margin-top: 7.5%;
        border-color: black;
    }
    .modal-button {
        width: 150px;
        height: 35px;
        margin: 15px auto;
        display: block;
        margin-top: 5%;
        font-family: HelveticaBold;
        background-color: black;
        color: white;
    }
    @media only screen and (max-width:500px) {
        .modal-window {
        width: 350px;
        }
  }
</style>