<template>
  <div>
    <vueper-slides :slide-ratio="3/4">
      <vueper-slide v-for="photo in photos" 
      :key="photo.name" 
      :image="photo.image"
      />
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  data() {
    return {
      photos: [],
    }
  },
  components: { VueperSlides, VueperSlide },
  watch: {
    '$router.currentRoute.value.fullPath'() {
      this.refreshPhotos();
    }
  },
  methods: {
    refreshPhotos() {
      const api = this.apiLink + 'variants/?id=' + this.$route.params.productId;
      console.log(api);
      this.axios.get(api).then(function(response) {
        return response.data;
      }).then((data) => {
        this.photos = data.results[0].photos;
        console.log(data.results[0]);
      });
    }
  },
  mounted() {
    this.refreshPhotos();
  }
}
</script>

<style>
.vueperslides__arrow {
  color: black !important;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
  box-shadow: none;
}
</style>