import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainButton from './components/UI/MainButton.vue';
import SearchInput from './components/UI/SearchInput.vue';
import i18n from './i18n';
import { createRouter,createWebHistory } from 'vue-router';
import ProductList from './components/layout/ProductList.vue';
import WelcomePage from './components/layout/WelcomePage.vue';
import ProductDetail from './components/layout/ProductDetail.vue';
import TheWishlist from './components/layout/TheWishlist.vue';
import TheIndex from './components/layout/TheIndex.vue';
import TheContact from './components/layout/TheContact.vue';
import TheGDPR from './components/layout/TheGDPR.vue';
import TheCooperation from './components/layout/TheCooperation.vue';
// import AdminPannel from './components/layout/admin/AdminPannel.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import "./assets/css/app.css";
import "./assets/css/aos.min.css";
import "./assets/css/normalize.css";
import "./assets/css/slick.min.css";
import 'jquery';
import 'aos/dist/aos.css';
import 'slick-carousel';
import store from './store.js';
import VueCookies from 'vue-cookies';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'furnitures',
      path: '/furnitures',
      component: ProductList,
      meta: {title: 'BSG Pro'}
    },
    // {
    //   name: 'admin',
    //   path: '/admin',
    //   component: AdminPannel,
    //   beforeEnter: (to, from, next) => {
    //     if (store.state.isAdmin) {
    //       next();
    //     }
    //     else {
    //       next('/');
    //     }
    //   }
    // },
    { 
      name: 'furniture-detail',
      path: '/furnitures/:productId',
      component: ProductDetail,
      props: true, 
      meta: {title: 'BSG Pro'}
    },
    {
      name: 'wishlist',
      path: '/wishlist',
      component: TheWishlist,
      props: true, 
      meta: {title: 'BSG Pro'}
    },
    {path: '/welcome', component: WelcomePage, meta: {title: 'BSG Pro'}},
    {path: '/contact', component: TheContact, meta: {title: 'BSG Pro'}},
    {path: '/cooperation', component: TheCooperation, meta: {reload: true, title: 'BSG Pro'}},
    {path: '/gdpr', component: TheGDPR, meta: {title: 'BSG Pro'}},
    {path: '/', component: TheIndex, meta: {reload: true, title: 'BSG Pro'}},
  ],
});

router.afterEach((to, from) => {
  if (to.path == "/" && from.path != "/") {
    window.location.reload();
  }
  if (to.path == "/contact" || to.path == "/welcome") {
    window.scrollTo(0,0);
  }
});
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


const app = createApp(App).use(i18n).use(VueAxios, axios).use(ElementPlus).use(store).use('slick-carousel').use(VueCookies)//.use(mail);//.use(naive);
app.config.globalProperties.apiLink = "https://api.bsg-pro.com/";
app.component('main-button', MainButton);
app.component('search-input',SearchInput);
app.use(router);
app.mount('#app');