<template>
<div>
  <ul class="list-group">
    <li class="list-group-item">
      <router-link :to="{path: '/furnitures/'}" class="allproducts" :class="{ boldCategory: currentCategory == 'All Products'}">
      {{ $t("allProducts") }}</router-link>
    </li>
    <li class="list-group-item" 
    v-for="category in categories" 
    :key="category.name">
    <router-link :to="{path: '/furnitures/', query: {furnitureCategory: category.lookfor}}" :class="{ boldCategory: currentCategory == category.lookfor}">
    {{ $t(category.name) }}
    </router-link>
    </li>
  </ul>
  
</div> 
</template>

<script>

export default {
  data() {
    return {
      currentCategory: "All Products",
      categories: [
        {
          name: "Sofas",
          lookfor: "Sofa"
        },
        {
          name: "Armchairs",
          lookfor: "Armchair"
        },
        {
          name: "Chaise Longues",
          lookfor: "Chaise Longue"

        },
        {
          name: "Poufs",
          lookfor: "Pouf"
        },
        {
          name: "Chairs",
          lookfor: "Chair"
        },
        {
          name: "Beds",
          lookfor: "Bed"
        },
        {
          name: "Mattresses",
          lookfor: "Mattress"
        },
        {
          name: "Headboards",
          lookfor: "Headboard"
        },
        {
          name: "Tables",
          lookfor: "Table"
        }
      ]
    };
  },
  watch: {
    "$route.query.furnitureCategory"() {
      this.currentCategory = this.$route.query.furnitureCategory;
      console.log(this.currentCategory);
    }
  }
}
</script>

<style scoped>

.list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

a {
  text-decoration: none;
  color: #707070;
  font-weight: 300;
}

a:hover {
  color: #071A8D;
}

.allproducts {
  font-weight: 500;
}

.boldCategory {
  font-weight: 1000;
  color: #4d4d4d;
}
</style>