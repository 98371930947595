<template>
  <div class="vcolors">
    <h1 class="display-4">{{ $t('moreColours') }}</h1>
    <hr>
    <div class="color-cards">
      <color-card @showSlideShow="showSlideShow"
      v-for="variant in variants.variants" 
      :key="variant.id" 
      :id="variant.id" 
      :variant="variant" 
      :title="variants.title" 
      :image="variant.photos[0].image"></color-card>
    </div>
  </div>
</template>

<script>
import ColorCard from '../../UI/ColorCard.vue';
export default {
  components: { ColorCard },
  emits: ['showComponent', 'rerender'],
  props: ['variants'],
  methods: {
    showSlideShow() {
      this.$emit('showComponent', 'slide-show');
      this.$emit('rerender');
    }
  }
}
</script>

<style scoped>
.color-cards {
  overflow: auto;
  width: 100%;
  max-height: 400px;
}

.vcolors {
  width: 90%;
  margin: auto;
}

h2 {
  text-align: left;
}

.mtop {
  margin-top: 5%;
}

hr {
    border-color: #697079;
    background-color: #697079;
    color: #697079;
}
</style>